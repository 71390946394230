div.stack {
  display: inline-block;
  position: relative;
  background: inherit;
  padding: 0px;
  margin: 0px;
}

div.stack > img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: inherit;
}
