.square {
  margin: 0px;
  padding: 0px;
  display: inline-block;
}

.square.square-l {
  background: #eeeeee;
}
.square.square-d {
  background: #cccccc;
}

.square.highlight-selected {
  background: #cc6666;
}
